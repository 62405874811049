<template>
    <div>
        <div class="panoramica">
        <component :is="infoTemplateComponent" :info="options.info[0]" :isOnComputer="true"></component>
        <!--<Pano class="panoramica" :source="getUrlImage"></Pano>-->
        </div>
        <qrBox></qrBox>
    </div>
</template>

<script>
import qrBox from './qrBox.vue';
import infoTemplatesRegistry from "@/components/controllers/infoTemplates/infoTemplatesRegistry";
//Otra libreria basada tambien en Panolens. Funciono bien pero no llegue a encontrar la rotacion automatica
//import { Pano } from 'vuejs-vr';


export default {
    components: {
        ...infoTemplatesRegistry.components,
        qrBox,
        //Pano,
    },
    props: {
        options: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
        };
    },
    computed: {
        infoTemplateComponent() {
            if (!this.options.info[0]) {
                return false;
            }
            return infoTemplatesRegistry.GetComponent(this.options.info[0].blockType)
        },
        //getUrlImage() { return this.options.info[0].image.url;}
    },
    watch: {

    },

};
</script>


<style scoped>
.panoramica {
    width: 100%;
    height: 100%;
    overflow: visible;
    position: absolute;
    top: 0px;
    left: 0px;
}
</style>
